<template>
  <div class="inline-block">
    <ColorTextBtn class="line" @click="showDialog">编辑 </ColorTextBtn>
    <EForm
      v-if="dialogVisible"
      ref="form"
      title="编辑平台"
      :dialogVisible.sync="dialogVisible"
      :sup_this="sup_this"
      :is-add="true"
      v-bind="$attrs"
    />
  </div>
</template>
<script>
import { commonEheaderMixin } from '@/mixins'
import EForm from './form'
export default {
  mixins: [commonEheaderMixin],
  components: { EForm },
  props: {
    sup_this: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  }
}
</script>
